import React from "react";
import LayoutMemo from "../core/ui/components/Layout/Layout";
import "../App.css";

function App() {
    return (
        <div className="App">
            <LayoutMemo />
        </div>
    );
}

export default App;
