import React from "react";
import ExchangeCalculator from "./ExchangeCalculator";

function HomePage() {
    return (
        <div>
            <ExchangeCalculator />
        </div>
    );
}

export default HomePage;
